import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Tooltip, Divider } from "antd";
import "chart.js/auto";
import "./politicasChart.css"; // Importa la hoja de estilos

const InfraestructuraChart = () => {
  const [fontSize, setFontSize] = useState(14); // Tamaño de fuente predeterminado

  // Función para ajustar el tamaño de la fuente según el tamaño de la pantalla
  const adjustFontSize = () => {
    if (window.innerWidth < 768) {
      setFontSize(7); // Tamaño de fuente para móviles
    } else {
      setFontSize(16); // Tamaño de fuente para pantallas más grandes
    }
  };

  // Ejecuta el ajuste del tamaño de fuente cuando se carga el componente y al cambiar el tamaño de la ventana
  useEffect(() => {
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);

  const data = [
    {
      nombreCompleto:
        "Construcción sistema de agua potable Aldea Valeriano, municipio de Concepción Las Minas, Chiquimula",
      abreviatura: ["Pozo ", "Valeriano"],
      presupuestado: 31000.0,
      ejecutado: 683110.48,
    },
    {
      nombreCompleto:
        "Mejoramiento calle y drenaje sanitario Barrio La Loma, Concepción Las Minas, Chiquimula",
      abreviatura: ["Calle y drenaje ", "La Loma"],
      presupuestado: 454000.0,
      ejecutado: 304681.2,
    },
    {
      nombreCompleto:
        "Mejoramiento calle y drenaje sanitario Cabecera Municipal, Concepción Las Minas, Chiquimula",
      abreviatura: ["Calle y drenaje ", "Cabecera"],
      presupuestado: 276000.0,
      ejecutado: 160993.0,
    },
    {
      nombreCompleto:
        "Construcción escuela primaria Aldea Monte Barroso, Concepción Las Minas, Chiquimula",
      abreviatura: ["Escuela ", "Monte Barroso"],
      presupuestado: 87500.0,
      ejecutado: 311212.5,
    },
    {
      nombreCompleto:
        "Construcción redes y líneas eléctricas de distribución Caserío El Aguajal, Aldea El Socorro, Concepción Las Minas, Chiquimula",
      abreviatura: ["Líneas eléctrias ", "El Aguajal"],
      presupuestado: 726000.0,
      ejecutado: 409171.6,
    },
    {
      nombreCompleto:
        "Mejoramiento camino rural Parada de Buses Aldea La Ermita, Concepción Las Minas, Chiquimula",
      abreviatura: ["Parada de buses ", "La Ermita"],
      presupuestado: 254000.0,
      ejecutado: 253200.0,
    },
    {
      nombreCompleto:
        "Mejoramiento camino rural por medio de pavimento Aldea San Antonio, Concepción Las Minas, Chiquimula",
      abreviatura: ["Pavimento ", "San Antonio"],
      presupuestado: 2007000.0,
      ejecutado: 1818740.0,
    },
    {
      nombreCompleto:
        "Construcción instalaciones deportivas y recreativas gimnasio municipal Concepción Las Minas, Departamento de Chiquimula",
      abreviatura: ["Construcción ", "Gimnasio"],
      presupuestado: 4800000.0,
      ejecutado: 2400000.0,
    },
    {
      nombreCompleto: "Construcción pozo Caserío Solapado",
      abreviatura: ["Pozo ", "El Solapado"],
      presupuestado: 599995.06,
      ejecutado: 599995.06,
    },
    {
      nombreCompleto: "Construcción escuela primaria El Jícaro",
      abreviatura: ["Escuela ", "El Jícaro"],
      presupuestado: 126988.83,
      ejecutado: 126988.83,
    },
  ];

  const sortedData = data.sort((a, b) => b.ejecutado - a.ejecutado);

  const formatNumber = (num) => {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const chartData = {
    labels: sortedData.map((item) => item.abreviatura),
    datasets: [
      {
        label: "Presupuestado",
        data: sortedData.map((item) => item.presupuestado),
        backgroundColor: "#06cc",
      },
      {
        label: "Ejecutado",
        data: sortedData.map((item) => item.ejecutado),
        backgroundColor: "#0cfc",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `Q${formatNumber(value)}`;
          },
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
    },
  };

  return (
    <div className="container-chart">
      <h2 className="title-chart">Infraestructura</h2>
      <p className="subtitle-chart">Año 2024</p>
      <div className="header-chart">
        <div>
          <p>
            <strong>Proyección total:</strong> Q {formatNumber(10918000.0)}
            <br />
            <strong>Total ejecutado:</strong> Q {formatNumber(7068092.67)}
          </p>
        </div>
      </div>
      <div className="bar-chart">
        <Bar data={chartData} options={options} />
      </div>

      <Divider />

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th> </th>
              {sortedData.map((item, index) => (
                <Tooltip
                  title={item.nombreCompleto}
                  overlayInnerStyle={{ fontSize: "1.75em" }}
                >
                  <th key={index}>{item.abreviatura}</th>
                </Tooltip>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="left-align">
                <strong>Presupuesto (Q)</strong>
              </td>
              {sortedData.map((item, index) => (
                <td key={index}>{formatNumber(item.presupuestado)}</td>
              ))}
            </tr>
            <tr>
              <td className="left-align">
                <strong>Ejecutado (Q)</strong>
              </td>
              {sortedData.map((item, index) => (
                <td key={index}>{formatNumber(item.ejecutado)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InfraestructuraChart;

import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Tooltip, Divider } from "antd";
import "chart.js/auto";
import "./politicasChart.css"; // Importa la hoja de estilos

const EducacionChart = () => {
  const [fontSize, setFontSize] = useState(14); // Tamaño de fuente predeterminado

  // Función para ajustar el tamaño de la fuente según el tamaño de la pantalla
  const adjustFontSize = () => {
    if (window.innerWidth < 768) {
      setFontSize(7); // Tamaño de fuente para móviles
    } else {
      setFontSize(16); // Tamaño de fuente para pantallas más grandes
    }
  };

  // Ejecuta el ajuste del tamaño de fuente cuando se carga el componente y al cambiar el tamaño de la ventana
  useEffect(() => {
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);

  const data = [
    {
      nombreCompleto:
        "Capacitación a la educación de mujeres, jóvenes, niños y adultos mayores, área urbana y rural Concepción Las Minas, Chiquimula",
      abreviatura: "Capacitaciones",
      presupuestado: 394000.0,
      ejecutado: 530444.91,
    },
    {
      nombreCompleto:
        "Apoyo a la educación escuela municipal de deportes, Concepción Las Minas, Chiquimula",
      abreviatura: "Deportes",
      presupuestado: 392000.0,
      ejecutado: 474804.79,
    },
    {
      nombreCompleto:
        "Apoyo a la educación escuela municipal de música y arte, Concepción Las Minas, Chiquimula",
      abreviatura: "Música y Arte",
      presupuestado: 394000.0,
      ejecutado: 382966.0,
    },
    {
      nombreCompleto:
        "Apoyo a la educación del ministerio de educación, área urbana y rural Concepción Las Minas, Chiquimula",
      abreviatura: "MINEDUC",
      presupuestado: 1062336.0,
      ejecutado: 1543054.96,
    },
    {
      nombreCompleto:
        "Apoyo a la educación colegio municipal de educación diversificada (cmed), Concepción Las Minas, Chiquimula",
      abreviatura: "CMED",
      presupuestado: 486000.0,
      ejecutado: 537779.02,
    },
  ];

  // Calcular proyección total y total ejecutado dinámicamente antes de ordenar
  const totalPresupuestado = parseFloat(
    data.reduce((acc, curr) => acc + curr.presupuestado, 0).toFixed(2)
  );
  const totalEjecutado = parseFloat(
    data.reduce((acc, curr) => acc + curr.ejecutado, 0).toFixed(2)
  );

  // Ordenar los datos por el campo "ejecutado" en orden descendente sin modificar el arreglo original
  const sortedData = [...data].sort((a, b) => b.ejecutado - a.ejecutado);

  const formatNumber = (num) => {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const chartData = {
    labels: sortedData.map((item) => item.abreviatura),
    datasets: [
      {
        label: "Presupuestado",
        data: sortedData.map((item) => item.presupuestado),
        backgroundColor: "#06cc",
      },
      {
        label: "Ejecutado",
        data: sortedData.map((item) => item.ejecutado),
        backgroundColor: "#0cfc",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `Q${formatNumber(value)}`;
          },
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: fontSize, // Usamos el tamaño de fuente dinámico
          },
        },
      },
    },
  };

  return (
    <div className="container-chart">
      <h2 className="title-chart">Educación</h2>
      <p className="subtitle-chart">Año 2024</p>
      <div className="header-chart">
        <div>
          <p>
            <strong>Proyección total:</strong> Q{" "}
            {formatNumber(totalPresupuestado)}
            <br />
            <strong>Total ejecutado:</strong> Q {formatNumber(totalEjecutado)}
          </p>
        </div>
      </div>
      <div className="bar-chart">
        <Bar data={chartData} options={options} />
      </div>

      <Divider />

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th> </th>
              {sortedData.map((item, index) => (
                <Tooltip
                  key={index}
                  title={item.nombreCompleto}
                  overlayInnerStyle={{ fontSize: "1.75em" }}
                >
                  <th>{item.abreviatura}</th>
                </Tooltip>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="left-align">
                <strong>Presupuesto (Q)</strong>
              </td>
              {sortedData.map((item, index) => (
                <td key={index}>{formatNumber(item.presupuestado)}</td>
              ))}
            </tr>
            <tr>
              <td className="left-align">
                <strong>Ejecutado (Q)</strong>
              </td>
              {sortedData.map((item, index) => (
                <td key={index}>{formatNumber(item.ejecutado)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EducacionChart;

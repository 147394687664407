import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import CountUp from "react-countup";
import "./politicasChart.css";
import LazyLoad from "react-lazyload";

const PoliticasChart = () => {
  const [fontSize, setFontSize] = useState(14); // Tamaño de fuente predeterminado

  // Función para ajustar el tamaño de la fuente según el tamaño de la pantalla
  const adjustFontSize = () => {
    if (window.innerWidth < 768) {
      setFontSize(7); // Tamaño de fuente para móviles
    } else {
      setFontSize(13); // Tamaño de fuente para pantallas más grandes
    }
  };

  // Ejecuta el ajuste del tamaño de fuente cuando se carga el componente y al cambiar el tamaño de la ventana
  useEffect(() => {
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);

  const data = [
    {
      nombre: ["Actividades", "Centrales"],
      presupuestado: 7213200.0,
      ejecutado: 6878313.37,
    },
    {
      nombre: ["Educación"],
      presupuestado: 2728336.0,
      ejecutado: 3469049.68,
    },
    {
      nombre: ["Red", "Vial"],
      presupuestado: 9360300.0,
      ejecutado: 8697142.48,
    },
    {
      nombre: ["Servicios", "Públicos"],
      presupuestado: 1551664.0,
      ejecutado: 1914036.23,
    },
    {
      nombre: ["Medio", "Ambiente"],
      presupuestado: 2330200.0,
      ejecutado: 2917683.25,
    },
    {
      nombre: ["Desarrollo", "Económico", "Local"],
      presupuestado: 919300.0,
      ejecutado: 839866.54,
    },
    {
      nombre: ["Seguridad"],
      presupuestado: 946000.0,
      ejecutado: 991708.27,
    },
    { nombre: ["Salud"], presupuestado: 84500.0, ejecutado: 129575.82 },
    {
      nombre: ["Obras de", "Infraestructura"],
      presupuestado: 10918000.0,
      ejecutado: 7068092.67,
    },
    {
      nombre: ["Asistencia", "Técnica", "DMP"],
      presupuestado: 442000.0,
      ejecutado: 501877.8,
    },
    {
      nombre: ["Préstamos", " y convenios", "de pago"],
      presupuestado: 2080000.0,
      ejecutado: 1729422.6,
    },
  ];

  // Calcular los totales
  const totalPresupuestado = data.reduce(
    (sum, item) => sum + item.presupuestado,
    0
  );
  const totalEjecutado = data.reduce((sum, item) => sum + item.ejecutado, 0);

  const sortedData = data.sort((a, b) => b.ejecutado - a.ejecutado);

  const chartData = {
    labels: sortedData.map((item) => item.nombre),
    datasets: [
      {
        label: "Presupuestado",
        data: sortedData.map((item) => item.presupuestado),
        backgroundColor: "#06cc",
      },
      {
        label: "Ejecutado",
        data: sortedData.map((item) => item.ejecutado),
        backgroundColor: "#0cfc",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            size: fontSize,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: fontSize,
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: fontSize,
          },
        },
      },
    },
  };

  return (
    <div className="container-chart">
      <LazyLoad>
        <h2 className="title-chart">Políticas Públicas</h2>
        <p className="subtitle-chart">Año 2024</p>
        <div className="header-chart">
          <div>
            <p>
              <strong>Proyección total:</strong> Q
              <CountUp
                start={0}
                end={totalPresupuestado}
                duration={3}
                separator=","
                decimals={2}
                formatter={(value) => value.toFixed(2)} // Asegura dos decimales
              />
              <br />
              <strong>Total ejecutado:</strong> Q
              <CountUp
                start={0}
                end={totalEjecutado}
                duration={3}
                separator=","
                decimals={2}
                formatter={(value) => value.toFixed(2)} // Asegura dos decimales
              />
            </p>
          </div>
        </div>
        <div className="bar-chart">
          <Bar data={chartData} options={options} />
        </div>
      </LazyLoad>
      <LazyLoad offset={-200}>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th></th>
                {sortedData.map((item, index) => (
                  <th key={index}>{item.nombre.join(" ")}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="left-align">
                  <strong>Presupuesto (Q)</strong>
                </td>
                {sortedData.map((item, index) => (
                  <td key={index}>
                    <CountUp
                      start={0}
                      end={item.presupuestado}
                      duration={2}
                      separator=","
                      decimals={2}
                      formatter={(value) => value.toFixed(2)} // Asegura dos decimales
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td className="left-align">
                  <strong>Ejecutado (Q)</strong>
                </td>
                {sortedData.map((item, index) => (
                  <td key={index}>
                    <CountUp
                      start={0}
                      end={item.ejecutado}
                      duration={2}
                      separator=","
                      decimals={2}
                      formatter={(value) => value.toFixed(2)} // Asegura dos decimales
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </LazyLoad>
    </div>
  );
};

export default PoliticasChart;

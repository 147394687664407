import React, { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Slider from "react-slick";
import { Dropdown, Menu, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCity,
  faChartLine,
  faMoneyBillWave,
  faBuilding,
  faShieldAlt,
  faHeartbeat,
  faTree,
  faUsers,
  faHandsHelping,
  faFileDownload,
  faRoad,
  faGraduationCap,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Configuración del worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Memoria = ({ pdfFile }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0); // Ancho dinámico del contenedor
  const sliderRef = useRef();

  // Calcular el ancho dinámico del contenedor según el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      const availableWidth =
        window.innerWidth > 1200 ? 1000 : window.innerWidth * 0.9; // Máximo 1000px en pantallas grandes
      setContainerWidth(availableWidth);
    };

    handleResize(); // Calcular inicialmente
    window.addEventListener("resize", handleResize); // Recalcular al cambiar el tamaño de la ventana
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const sliderSettings = {
    dots: false, // Sin puntos de navegación
    infinite: false, // Navegación finita
    speed: 500,
    slidesToShow: 1, // Mostrar solo una página por vez
    slidesToScroll: 1,
    lazyLoad: "progressive", // Carga progresiva
    centerMode: false, // Sin centrado adicional
    adaptiveHeight: true, // Altura ajustada al contenido
    beforeChange: (_, newIndex) => setCurrentPage(newIndex + 1),
  };

  const goToPage = (page) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(page - 1); // Navegar a la página seleccionada
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      goToPage(currentPage + 1);
    }
  };


const menu = (
  <Menu>
    <Menu.Item
      key="inicio"
      onClick={() => goToPage(1)}
      style={{
        fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
        <FontAwesomeIcon icon={faHome} style={{ marginRight: "1em" }} />
        <span>Inicio</span>
      </div>
    </Menu.Item>

    <Menu.Item
      key="alcaldia"
      onClick={() => goToPage(2)}
      style={{
        fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
        <FontAwesomeIcon icon={faUsers} style={{ marginRight: "1em" }} />
        <span>Alcaldía</span>
      </div>
    </Menu.Item>

    <Menu.Item
      key="visionMision"
      onClick={() => goToPage(4)}
      style={{
        fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
        <FontAwesomeIcon icon={faChartLine} style={{ marginRight: "1em" }} />
        <span style={{ lineHeight: "1.2em" }}>
          Visión <br /> y Misión
        </span>
      </div>
    </Menu.Item>

    <Menu.Item
      key="ingresos"
      onClick={() => goToPage(5)}
      style={{
        fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
        <FontAwesomeIcon icon={faMoneyBillWave} style={{ marginRight: "1em" }} />
        <span style={{ lineHeight: "1.2em" }}>
          Ingresos <br /> Municipales
        </span>
      </div>
    </Menu.Item>

    <Menu.SubMenu
      key="politicas"
      title={
        <span
          style={{
            fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
          }}
        >
                  <div style={{ display: "flex", alignItems: "center", padding:"0.5em" }}>
          <FontAwesomeIcon icon={faHandsHelping} style={{ marginRight: "1em" }} />
          <span>Políticas <br/>Públicas</span>
        </div>
        </span>
      }
    >
      <Menu.Item
        key="politicaspublicas"
        onClick={() => goToPage(6)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faHandsHelping} style={{ marginRight: "1em" }} />
          <span>Políticas <br/>Públicas</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="politica1"
        onClick={() => goToPage(8)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: "1em" }} />
          <span>Educación</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="politica2"
        onClick={() => goToPage(10)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faRoad} style={{ marginRight: "1em" }} />
          <span>Red Vial</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="politica3"
        onClick={() => goToPage(12)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faBuilding} style={{ marginRight: "1em" }} />
          <span>Servicios<br/> Públicos</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="politica4"
        onClick={() => goToPage(14)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faShieldAlt} style={{ marginRight: "1em" }} />
          <span>Seguridad</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="politica5"
        onClick={() => goToPage(16)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faHeartbeat} style={{ marginRight: "1em" }} />
          <span>Salud</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="politica6"
        onClick={() => goToPage(18)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faTree} style={{ marginRight: "1em" }} />
          <span>Medio<br/> Ambiente</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="politica7"
        onClick={() => goToPage(20)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faChartLine} style={{ marginRight: "1em" }} />
          <span>Desarrollo<br/>Económico<br/>Local</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="politica0"
        onClick={() => goToPage(22)}
        style={{
          fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
          <FontAwesomeIcon icon={faCity} style={{ marginRight: "1em" }} />
          <span>Obras de<br/>Infraestructura</span>
        </div>
      </Menu.Item>
    </Menu.SubMenu>

    <Menu.Item
      key="proyectos"
      onClick={() => goToPage(26)}
      style={{
        fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: "1em" }} />
        <span>Proyectos y<br/>Actividades</span>
      </div>
    </Menu.Item>

    <Menu.Item
      key="egresos"
      onClick={() => goToPage(74)}
      style={{
        fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
        <FontAwesomeIcon icon={faMoneyBillWave} style={{ marginRight: "1em" }} />
        <span>Egresos<br/>Municipales</span>
      </div>
    </Menu.Item>

    <Menu.Item
      key="organigrama"
      onClick={() => goToPage(75)}
      style={{
        fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
        <FontAwesomeIcon icon={faProjectDiagram} style={{ marginRight: "1em" }} />
        <span>Organigrama</span>
      </div>
    </Menu.Item>

    <Menu.Divider />

    <Menu.Item
      key="descargar"
      onClick={() =>
        window.open("http://www.tumuniclm.com/documentos/Memoriadelabores2024.pdf", "_blank")
      }
      style={{
        fontSize: window.innerWidth <= 768 ? "1em" : "1.25em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding:"0.25em" }}>
        <FontAwesomeIcon icon={faFileDownload} style={{ marginRight: "1em" }} />
        <span>Imprimir,<br/>Descargar PDF</span>
      </div>
    </Menu.Item>
  </Menu>
);


  return (
    <div style={{ textAlign: window.innerWidth <= 768 ? "left" : "center", paddingTop: "1em" }}>
      <div
        style={{
          width: `${containerWidth}px`,
          maxWidth: "100%",
          margin: "0 auto", // Centrado horizontal
          overflow: "hidden",
        }}
      >
        <div style={{  marginBottom:"1em", display: "left", alignItems: "center" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                style={{
                  marginRight:"1em",
                  fontSize: window.innerWidth <= 768 ? "1em" : "1.25em", // Tamaño de fuente dinámico
                }}
              >
                Menú
              </Button>
            </Dropdown>
            {/* Texto alineado verticalmente */}
            Página {currentPage} de {numPages}
        </div>
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<p>Cargando Contenido...</p>}
          error={<p>No se pudo cargar el Contenido</p>}
        >
          {numPages && (
            <Slider ref={sliderRef} {...sliderSettings}>
              {Array.from({ length: numPages }, (_, index) => (
                <div key={index}>
                  <Page
                    pageNumber={index + 1}
                    width={containerWidth} // Ajustar dinámicamente al ancho del contenedor
                    renderMode="canvas"
                    loading={<p>Cargando página {index + 1}...</p>}
                  />
                </div>
              ))}
            </Slider>
          )}
        </Document>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center", // JustifyContent dinámico
          marginLeft: window.innerWidth <= 768 ? "2em" : "0", // JustifyContent dinámico
          fontSize: "0.7em",
          alignItems: "center", // Alinear verticalmente
          gap: "10px", // Espacio entre los elementos
        }}
      >



        {/* Botón para ir a la página anterior */}
  <Button
    icon={<LeftOutlined />}
    onClick={handlePrevPage}
    disabled={currentPage === 1}
    style={{
      fontSize: window.innerWidth <= 768 ? "2em" : "2em", // Tamaño de fuente dinámico
    }}
  />
  <span 
  style={{
          color:"#06c",
          fontSize: window.innerWidth <= 768 ? "1em" : "2em",
        }} >Desliza o haz click para ver más </span>
  <Button
    icon={<RightOutlined />}
    onClick={handleNextPage}
    disabled={currentPage === numPages}
    style={{
      
      fontSize: window.innerWidth <= 768 ? "2em" : "2em", // Tamaño de fuente dinámico
    }}
  />
        </div>

    </div>
  );
};

export default Memoria;